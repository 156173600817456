/* Modern Font Import */
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bagel+Fat+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Shadow&display=swap');

/* Custom Granite Font */
@font-face {
  font-family: 'Granite';
  src: url('./fonts/BaksoSapi.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Global Color Variables */
:root {
  --background-color: #fae1d2;
  --text-color: #221E22;
  --drawing-color: #1124c0;
}

/* Reset and Global Styles */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrollbar */
  width: 100%;  /* Changed from 100vw which can cause issues */
  position: relative;
  height: auto; /* Allow natural height */
  overscroll-behavior-y: auto; /* Allow natural scrolling behavior */
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color) !important;
  color: var(--text-color);
  position: relative;
  overflow-x: hidden;
  overflow-y: auto !important; /* Force vertical scrolling always */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  touch-action: pan-y; /* Allow vertical touch scrolling */
}

.App {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden !important; /* Only allow one scrollbar on body */
  background: var(--background-color);
  min-height: 100vh;
  position: relative;
  width: 100%; /* Changed from 100vw which can cause issues */
  touch-action: pan-y; /* Allow vertical touch scrolling */
}

.event-list-container {
  position: relative;
  width: 100%;
  overflow: hidden !important; /* Changed from visible to hidden */
  touch-action: pan-y; /* Allow vertical touch scrolling */
}

/* Event list element to ensure proper stacking */
.event-list {
  position: relative;
  z-index: 2;
  padding: 0px;
  max-width: 70%;
  margin: 0 auto;
  overflow: hidden;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  touch-action: pan-y; /* Allow vertical scrolling */
}

/* Background drawings styles */
.background-drawings {
  position: absolute; /* Absolute instead of fixed to follow scroll */
  top: 0;
  left: 0;
  width: 100vh; /* Use percentage instead of viewport width */
  height: auto; /* Let it be controlled by React */
  min-height: 100vh;
  pointer-events: none;
  overflow: hidden !important; /* Changed from visible to hidden */
  z-index: 1;
  contain: layout paint; /* Improved performance */
  padding: 0;
  margin: 0;
}

.background-drawings img {
  /* Completely static images - no animation properties */
  pointer-events: none;
  display: block;
  max-width: 500px;
  max-height: 500px;
  transform: translate3d(0, 0, 0); /* Force hardware acceleration */
  /* Force SVG to appear correctly */
  stroke: var(--drawing-color);
  stroke-width: 2;
  /* Prevent any CSS animations */
  animation: none !important;
  transition: none !important;
  /* Prevent any boundary issues */
  padding: 0 !important;
  margin: 0 !important;
  box-sizing: border-box !important;
}

/* Don't apply any transition on hover */
.event-card:hover ~ .background-drawings img,
.header:hover ~ .background-drawings img {
  opacity: 0.5 !important;
  transition: none !important;
  animation: none !important;
  transform: none !important;
  -webkit-transform: none !important;
}

/* Ensure SVG elements are visible */
svg path, svg rect, svg circle, svg polygon, svg line {
  stroke: var(--drawing-color) !important;
  fill: none !important; 
}

/* Event card styles to ensure proper stacking */
.event-card {
  position: relative;
  z-index: 2; /* Ensure cards are above most drawings */
  backdrop-filter: blur(2px); /* Slightly stronger blur effect for better text readability */
  -webkit-backdrop-filter: blur(2px);
  background-color: rgba(250, 225, 210, 0.9); /* Background color with transparency */
  border: 1px solid rgba(5, 1, 1, 0.3) !important; /* Border using text color with opacity */
}

/* Progress Bar with dark purple accent */
.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 4px; /* Slightly thicker */
  background: var(--drawing-color); /* Drawing color for the progress bar */
  transform-origin: 0%;
  width: 100%;
  transform: scaleX(0);
  z-index: 100;
}

/* Header with background color */
.header {
  text-align: center;
  padding: 40px 20px;
  background: var(--background-color); /* Background color */
  border-bottom: 1px solid rgba(5, 1, 1, 0.1); /* Text color with opacity */
  z-index: 10;
}

.header-title {
  font-family: 'Londrina Shadow', 'Bagel Fat One', 'Granite', 'Space Grotesk', sans-serif;
  font-size: 5rem;
  font-weight: 1200;
  margin-bottom: 1rem;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: var(--drawing-color); /* Text color for titles */
}

.header-subtitle {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  opacity: 0.95;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.5;
  letter-spacing: 0.02em;
  color: var(--drawing-color); /* Text color */
}

/* Modern Search Bar */
.search-bar-container {
  position: relative; /* Changed from sticky to relative */
  margin: 80px auto 20px; /* Increased top margin to move it down */
  max-width: 600px;
  padding: 0 20px;
  z-index: 999;
  width: calc(100% - 40px); /* Ensure it doesn't overflow */
  box-sizing: border-box; /* Include padding in width calculation */
}

.search-input {
  width: 100%;
  padding: 16px 24px;
  border-radius: 16px;
  background-color: var(--background-color) !important; /* Background color */
  border: 1px solid var(--drawing-color); /* Text color for border */
  color: var(--drawing-color) !important; /* Text color */
  font-size: 1rem;
  transition: all 0.3s ease;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box; /* Include padding in width calculation */
}

.search-input::placeholder {
  color: var(--text-color);
  opacity: 0.5;
}

.search-input:focus {
  outline: none;
  background-color: var(--background-color) !important; /* Background color */
  border-color: var(--drawing-color); /* Drawing color for focus border */
  box-shadow: 0 0 0 4px rgba(17, 36, 192, 0.1); /* Drawing color with opacity */
}

/* Event List Styling */
.date-section {
  margin-bottom: 60px;
}

.date-heading {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--text-color); /* Text color for titles */
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(5, 1, 1, 0.3); /* Text color with opacity */
}

/* Event Cards Grid */
.events-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px; /* Reduced gap */
  padding: 0;
  margin: 0;
  width: 100%; /* Ensure full width containment */
  box-sizing: border-box; /* Include padding in width calculation */
}

.event-card {
  background: rgba(250, 225, 210, 0.92) !important; /* Background color with transparency */
  padding: 16px 16px 20px 16px; /* Increased bottom padding */
  border-radius: 16px;
  border: 1px solid var(--text-color); /* Text color for border */
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  height: auto; /* Changed from fixed height to auto */
  min-height: 120px; /* Maintain minimum height */
  max-height: none; /* Remove max-height constraint */
  display: flex;
  flex-direction: column;
  text-decoration: none; /* Remove underline */
  box-shadow: 0 4px 6px rgba(51, 24, 50, 0.05); /* Dark purple shadow */
  overflow: hidden; /* Changed from visible to hidden to prevent overflow */
  backdrop-filter: blur(2px); /* Slightly stronger blur effect for better text readability */
  -webkit-backdrop-filter: blur(2px);
  box-sizing: border-box; /* Include padding in width calculation */
  width: 100%; /* Ensure full width */
}

.event-card:hover {
  transform: translateY(-4px);
  background: rgba(250, 225, 210, 0.95) !important; /* Slightly more opaque on hover for better focus */
  border-color: var(--text-color); /* Text color for border */
  box-shadow: 0 10px 20px rgba(5, 1, 1, 0.15); /* Text color shadow with opacity */
}

/* Make sure text and content inside cards remains fully opaque */
.event-card * {
  transition: all 0.3s ease;
}

.event-card .event-title,
.events-container .event-title,
.event-title {
  font-size: clamp(0.95rem, 5vw, 1.25rem) !important; /* Responsive font size */
  font-weight: 500 !important; /* Medium weight instead of semi-bold */
  color: var(--text-color) !important;
  margin: 0 0 8px 0 !important; /* Reduced margin */
  line-height: 1.3 !important; /* Tighter line height */
  text-decoration: none !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important; /* Limit to 2 lines */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.event-location {
  font-size: 0.875rem; /* Match text-sm (0.875rem) */
  color: var(--text-color) !important;
  margin-bottom: 12px; /* Reduced margin */
  display: inline; /* Changed from flex to inline */
  text-decoration: none; /* Remove underline */
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 30px); /* Leave space for icon */
  word-wrap: break-word; /* Allow breaking words */
  white-space: normal; /* Allow text to wrap */
  line-height: 1.3; /* Better line height for wrapped text */
}

/* Add a container class for the location icon and text */
.location-container, .date-container {
  display: flex;
  align-items: flex-start; /* Align items to the top */
  gap: 4px; /* Small gap between icon and text */
}

.location-icon, .date-icon {
  flex-shrink: 0; /* Prevent icon from shrinking */
  margin-top: 2px; /* Slight adjustment to align with first line of text */
  color: var(--drawing-color) !important; /* Drawing color for icons */
}

/* Add margin to the date container */
.date-container {
  margin-bottom: 12px;
  color: var(--text-color) !important; /* Text color */
}

/* Language Switcher */
.language-switcher {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1010;
}

.lang-button {
  background-color: var(--background-color) !important;
  color: var(--drawing-color) !important;
  border: 1px solid var(--drawing-color);
  padding: 8px 16px;
  margin-left: 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  z-index: 2000;
}

.lang-button.active {
  background-color: var(--drawing-color) !important;
  border-color: var(--drawing-color);
  color: var(--background-color) !important;
  z-index: 2000;
}

/* Side date text indicator */
.side-date-indicator {
  position: fixed;
  top: 24px;
  left: 24px;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 0.9rem;
  color: var(--drawing-color) !important;
  background: var(--background-color);
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--drawing-color);
  z-index: 1000;
}

/* Remove the side progress bar styles since it's not needed */
.side-progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 4px;
  background: var(--drawing-color);
}

.event-submission-box {
  max-width: 600px;
  padding: 10px;
  background: var(--background-color); /* Background color */
  border-radius: 16px;
  border: 1px solid var(--text-color); /* Text color border */
  margin: auto;
  margin-bottom: 50px;
  box-shadow: 0 4px 6px rgba(5, 1, 1, 0.05); /* Text color shadow */
}

.event-submission-box h3 {
  font-family: 'Space Grotesk', sans-serif;
  color: var(--text-color) !important;
  text-align: center;
}

.event-input {
  width: calc(100% - 32px);
  padding: 15px;
  border-radius: 12px;
  background-color: var(--background-color) !important;
  border: 1px solid var(--text-color);
  color: var(--text-color) !important;
  margin-bottom: 12px;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.event-input::placeholder {
  color: var(--text-color);
  opacity: 0.7;
}

.submit-button {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  background: var(--drawing-color);
  border: none;
  color: var(--background-color);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
  background: var(--drawing-color);
}

/* Category filter for all screen sizes */
.category-filter {
  max-width: 70%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 12px auto; /* Further reduced margin */
  padding: 10px; /* Further reduced padding */
  border-radius: 12px;
}

/* Add subtle pattern to category filter */
.category-filter::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(circle at 10% 20%, rgba(5, 1, 1, 0.01) 0%, transparent 20%),
                    radial-gradient(circle at 90% 80%, rgba(5, 1, 1, 0.01) 0%, transparent 20%);
  pointer-events: none;
  z-index: 0;
}

.filter-tag {
  padding: 6px 10px; /* Even smaller padding to reduce height */
  border-radius: 10px;
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  border: 1px solid var(--text-color) !important;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 0.5);
  font-weight: 500; 
  font-size: 0.8rem; /* Smaller font to reduce height */
  box-shadow: 0 1px 1px rgba(5, 1, 1, 0.1); /* Further reduced shadow */
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  opacity: 1 !important;
  line-height: 1.2; /* Reduced line height */
}

/* Hover effect - show category color */
.filter-tag:hover {
  transform: translateY(-1px) scale(1.01); /* Minimal lift effect */
  box-shadow: 0 2px 6px rgba(5, 1, 1, 0.1);
}

/* Filter tag category-specific hover styles */
.filter-tag.music:hover {
  background-color: rgba(255, 107, 107, 0.8) !important; /* Coral Red with opacity */
}

.filter-tag.theater:hover, .filter-tag.dance:hover, .filter-tag.theater-dance:hover {
  background-color: rgba(186, 134, 252, 0.8) !important; /* Lighter purple with opacity */
}

.filter-tag.exhibition:hover {
  background-color: rgba(0, 201, 167, 0.8) !important; /* Teal with opacity */
}

.filter-tag.academic:hover {
  background-color: rgba(77, 128, 118, 0.8) !important; /* Dark Teal with opacity */
}

.filter-tag.film:hover {
  background-color: rgba(121, 80, 242, 0.8) !important; /* Rich purple with opacity */
}

.filter-tag.guided-tour:hover {
  background-color: rgba(197, 97, 131, 0.8) !important; /* Rose with opacity */
}

.filter-tag.sport:hover {
  background-color: rgba(255, 150, 113, 0.8) !important; /* Orange with opacity */
}

.filter-tag.festival:hover {
  background-color: rgba(255, 199, 95, 0.8) !important; /* Yellow with opacity */
}

.filter-tag.market:hover {
  background-color: rgba(249, 248, 113, 0.8) !important; /* Light Yellow with opacity */
}

.filter-tag.talks:hover, .filter-tag.education:hover, .filter-tag.talks-education:hover {
  background-color: rgba(3, 218, 198, 0.8) !important; /* Teal with opacity */
}


.filter-tag.tech:hover {
  background-color: rgba(74, 78, 105, 0.8) !important; /* Slate with opacity */
}

.filter-tag.vacation-camp:hover {
  background-color: rgba(184, 242, 230, 0.8) !important; /* Mint with opacity */
}

.filter-tag.opendeurdag:hover {
  background-color: rgba(255, 166, 158, 0.8) !important; /* Salmon with opacity */
}

.filter-tag.all:hover {
  background-color: rgba(62, 146, 204, 0.8) !important; /* Blue with opacity */
  color: #EDD1FA !important; /* Light lavender text */
}

/* Active state - full color */
.filter-tag.active {
  color: var(--background-color) !important;
  background-color: var(--text-color) !important;
  border-color: var(--text-color) !important;
  box-shadow: 0 6px 12px rgba(5, 1, 1, 0.3);
  transform: translateY(-2px); /* Slight lift effect */
  opacity: 1; /* Full opacity when active */
}

/* Category-specific active states */
.filter-tag.music.active {
  background-color: #FF6B6B !important; /* Coral Red */
  border-color: #FF6B6B !important; /* Coral Red border */
  color: #000000 !important; /* White text */
  box-shadow: 0 6px 12px rgba(255, 107, 107, 0.3); /* Matching shadow */
}

.filter-tag.theater.active, .filter-tag.dance.active, .filter-tag.theater-dance.active {
  background-color: #BA86FC !important; /* Lighter purple */
  border-color: #BA86FC !important; /* Lighter purple border */
  color: #000000 !important; /* Black text for better contrast */
  box-shadow: 0 6px 12px rgba(186, 134, 252, 0.3); /* Matching shadow */
}

.filter-tag.exhibition.active {
  background-color: #00C9A7 !important; /* Teal */
  border-color: #00C9A7 !important; /* Teal border */
  color: #FFFFFF !important; /* White text */
  box-shadow: 0 6px 12px rgba(0, 201, 167, 0.3); /* Matching shadow */
}

.filter-tag.academic.active {
  background-color: #4D8076 !important; /* Dark Teal */
  border-color: #4D8076 !important; /* Dark Teal border */
  color: #FFFFFF !important; /* White text */
  box-shadow: 0 6px 12px rgba(77, 128, 118, 0.3); /* Matching shadow */
}

.filter-tag.film.active {
  background-color: #7950F2 !important; /* Rich purple */
  border-color: #7950F2 !important; /* Rich purple border */
  color: #FFFFFF !important; /* White text */
  box-shadow: 0 6px 12px rgba(121, 80, 242, 0.3); /* Matching shadow */
}

.filter-tag.guided-tour.active {
  background-color: #C56183 !important; /* Rose */
  border-color: #C56183 !important; /* Rose border */
  color: #FFFFFF !important; /* White text */
  box-shadow: 0 6px 12px rgba(197, 97, 131, 0.3); /* Matching shadow */
}

.filter-tag.sport.active {
  color: var(--background-color) !important;
  background-color: #FF9671 !important;
  border-color: #FF9671 !important;
}

.filter-tag.festival.active {
  color: var(--background-color) !important;
  background-color: #FFC75F !important;
  border-color: #FFC75F !important;
}

.filter-tag.market.active {
  color: var(--background-color) !important;
  background-color: #F9F871 !important;
  border-color: #F9F871 !important;
}

.filter-tag.talks.active, .filter-tag.education.active, .filter-tag.talks-education.active {
  color: var(--background-color) !important;
  background-color: #03DAC6 !important;
  border-color: #03DAC6 !important;
}


.filter-tag.tech.active {
  background-color: #4A4E69 !important; /* Slate */
  border-color: #4A4E69 !important; /* Slate border */
  color: #FFFFFF !important; /* White text */
  box-shadow: 0 6px 12px rgba(74, 78, 105, 0.3); /* Matching shadow */
}

.filter-tag.vacation-camp.active {
  background-color: #B8F2E6 !important; /* Mint */
  border-color: #B8F2E6 !important; /* Mint border */
  color: #FFFFFF !important; /* White text */
  box-shadow: 0 6px 12px rgba(184, 242, 230, 0.3); /* Matching shadow */
}

.filter-tag.opendeurdag.active {
  background-color: #FFA69E !important; /* Salmon */
  border-color: #FFA69E !important; /* Salmon border */
  color: #FFFFFF !important; /* White text */
  box-shadow: 0 6px 12px rgba(255, 166, 158, 0.3); /* Matching shadow */
}


body .filter-tag.all.active {
  color: var(--background-color) !important;
  background-color: var(--drawing-color) !important;
  border-color: var(--text-color) !important;
}

.category-filter button.filter-tag.all.active {
  color: var(--background-color) !important;
  background-color: var(--drawing-color) !important;
  border-color: var(--drawing-color) !important;
}

html body div.category-filter button.filter-tag.all.active {
  color: var(--background-color) !important;
  background-color: var(--drawing-color) !important;
  border-color: var(--text-color) !important;
}

/* Event category wrapper */
.category-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 8px;
  overflow: hidden; /* Prevent overflow */
  width: 100%; /* Full width */
  box-sizing: border-box; /* Include padding in width calculation */
}

.event-category {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  margin-right: 6px;
  margin-bottom: 6px;
  border-radius: 8px;
  background-color: rgba(250, 225, 210, 0.3) !important;
  color: var(--text-color) !important;
  border: 1px solid var(--text-color) !important;
  font-size: 0.8rem !important;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(5, 1, 1, 0.1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  position: relative;
  overflow: hidden; /* Prevent text overflow */
  max-width: 100%; /* Limit width */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  white-space: nowrap;
  opacity: 1 !important;
  line-height: 1.2;
  height: auto;
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Event card content organization */
.event-content {
  margin-bottom: 10px;
}

/* Apply more specific category colors with higher specificity */
html body .event-category.theater, 
html body .event-category.dance, 
html body .event-category.theater-dance {
  background-color: #BA86FC !important; /* Lighter purple */
  color: #000000 !important; /* Black text for better contrast */
  border-color: #BA86FC !important;
  box-shadow: 0 2px 4px rgba(186, 134, 252, 0.3) !important;
}

html body .event-category.film {
  background-color: #7950F2 !important; /* Rich purple */
  color: #FFFFFF !important; /* White text */
  border-color: #7950F2 !important;
  box-shadow: 0 2px 4px rgba(121, 80, 242, 0.3) !important;
}

html body .event-category.talks,
html body .event-category.education,
html body .event-category.talks-education {
  background-color: #03DAC6 !important; /* Teal */
  color: #000000 !important; /* Black text for better contrast */
  border-color: #03DAC6 !important;
  box-shadow: 0 2px 4px rgba(3, 218, 198, 0.3) !important;
}

/* Higher specificity for filter tags too */
html body .filter-tag.theater.active, 
html body .filter-tag.dance.active, 
html body .filter-tag.theater-dance.active {
  background-color: #BA86FC !important; /* Lighter purple */
  border-color: #BA86FC !important;
  color: #000000 !important; /* Black text for better contrast */
  box-shadow: 0 6px 12px rgba(186, 134, 252, 0.3) !important;
}

html body .filter-tag.film.active {
  background-color: #7950F2 !important; /* Rich purple */
  border-color: #7950F2 !important;
  color: #FFFFFF !important; /* White text */
  box-shadow: 0 6px 12px rgba(121, 80, 242, 0.3) !important;
}

html body .filter-tag.talks.active, 
html body .filter-tag.education.active, 
html body .filter-tag.talks-education.active {
  background-color: #03DAC6 !important; /* Teal */
  border-color: #03DAC6 !important;
  color: #000000 !important; /* Black text for better contrast */
  box-shadow: 0 6px 12px rgba(3, 218, 198, 0.3) !important;
}

/* Make sure the App container doesn't cause scrollbar issues */
html, body {
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

/* Mobile-specific fixes for scrolling */
body {
  -webkit-overflow-scrolling: touch !important;
}

.events-container {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 8px;
  width: calc(100% - 20px);
  margin: 0 auto;
  overflow: visible;
  position: relative;
}

.side-progress-bar {
  left: 8px !important;
  z-index: 9999 !important;
  display: flex !important;
}

.side-date-indicator {
  left: 8px !important;
  top: 10px !important;
  z-index: 9999 !important;
  display: block !important;
}

.week-events-counter {
  position: absolute;
  left: 20px;
  bottom: 30px;
  backdrop-filter: blur(5px);
  border-radius: 12px;
  padding: 15px;
  color: var(--drawing-color);
  text-align: center;
  box-shadow: 0 4px 15px rgba(5, 1, 1, 0.1);
  z-index: 10;
  min-width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--drawing-color);
}

.week-events-counter .counter-label {
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 5px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.week-events-counter .counter-number {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.1;
}

@media (max-width: 768px) {
  .event-list {
    max-width: 90%; /* Increase event list width on mobile */
    padding: 0 4px; /* Add small padding to the sides */
  }
  
  .week-events-counter {
    left: 15px;
    bottom: 20px;
    padding: 10px;
    min-width: 70px;
    transform: scale(0.8);
    transform-origin: bottom left;
  }
  
  .week-events-counter .counter-number {
    font-size: 2rem;
  }
  
  .week-events-counter .counter-label {
    font-size: 0.7rem;
  }

  /* Update events grid layout */
  .events-container {
    gap: 12px;
    width: 100%;
  }

  /* Make category filter adjust for tablets */
  .category-filter {
    max-width: 90%;
    gap: 8px;
    margin: 10px auto;
  }
  
  .filter-tag {
    padding: 5px 8px;
    font-size: 0.75rem;
  }
}

@media (max-width: 480px) {
  .event-list {
    max-width: 90%; /* Further increase width on smaller screens */
    padding: 0 10px 0 10px; /* Increased horizontal padding for better spacing on right and left */
  }
  
  .date-heading {
    display: block; /* Make date heading visible again */
    font-size: 1.4rem; /* Smaller size for mobile */
    margin-bottom: 15px;
    padding-bottom: 8px;
  }
  
  .date-section {
    margin-bottom: 20px; /* Reduce margin since heading is hidden */
  }
  
  /* Make side date indicator fixed like desktop, not sticky */
  .side-date-indicator {
    position: fixed !important;
    top: 10px !important;
    left: 10px !important;
    right: auto !important; 
    display: block !important;
    margin: 0;
    font-size: 0.85rem;
    text-align: center;
    background-color: var(--background-color);
    padding: 6px 10px;
    border-radius: 8px;
    border: 1px solid var(--drawing-color);
    font-weight: 600;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 9999 !important;
  }
  
  /* Continue hiding date information in event cards */
  .date-container {
    display: none;
  }
  
  /* Give more prominence to location since date is hidden */
  .location-container {
    margin-bottom: 8px;
  }
  
  /* Optimize grid layout for small screens - 2 events per row with better containment */
  .events-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    width: 100%;
    padding: 0 10px; /* Increased from 4px to 6px for more space */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  
  /* Improve event card layout on mobile */
  .event-card {
    padding: 8px; /* Reduce padding further to prevent overflow */
    min-height: 90px;
    max-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden; /* Ensure no overflow */
  }
  
  /* Reduce title font size */
  .event-card .event-title,
  .events-container .event-title,
  .event-title {
    font-size: clamp(0.8rem, 4vw, 1rem) !important;
    margin: 0 0 6px 0 !important;
    -webkit-line-clamp: 2 !important;
  }
  
  /* Reduce location font size */
  .event-location {
    font-size: 0.75rem;
    margin-bottom: 8px;
  }
  
  /* Make location icons smaller */
  .location-icon svg, .date-icon svg {
    width: 14px !important;
    height: 14px !important;
  }
  
  /* Make category tags smaller and ensure they stay within cards */
  .event-category {
    padding: 3px 5px;
    font-size: 0.65rem !important;
    margin-right: 3px;
    margin-bottom: 3px;
    max-width: calc(100% - 6px); /* Ensure it stays within boundaries */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Ensure category wrapper contains its children */
  .category-wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin-top: 4px;
  }
  
  /* Make category filter take full width on mobile but more compact */
  .category-filter {
    max-width: 95%;
    gap: 4px;
    margin: 6px auto;
    padding: 4px;
  }
  
  /* Make filter tags smaller */
  .filter-tag {
    padding: 3px 5px;
    font-size: 0.65rem;
    border-radius: 6px;
    margin: 2px;
    line-height: 1;
  }
  
  /* Improve search bar for mobile */
  .search-bar-container {
    margin: 60px auto 10px;
    max-width: 95%;
  }
  
  .search-input {
    padding: 12px 16px;
    font-size: 0.9rem;
  }
  
  /* Adjust counter for mobile */
  .week-events-counter {
    left: auto;
    right: 10px;
    bottom: 10px;
    padding: 5px;
    transform: scale(0.6);
    transform-origin: bottom right;
    min-width: 60px;
    opacity: 0.8;
  }
  
  .week-events-counter .counter-number {
    font-size: 1.5rem;
  }
  
  .week-events-counter .counter-label {
    font-size: 0.6rem;
  }
  
  /* Ensure content stays contained on small screens */
  .event-content {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  /* Reduce title font size further if needed */
  .event-card .event-title,
  .events-container .event-title,
  .event-title {
    font-size: clamp(0.75rem, 3.5vw, 0.9rem) !important;
    margin: 0 0 4px 0 !important;
    -webkit-line-clamp: 2 !important;
    max-width: 100%;
    overflow: hidden;
  }
  
  /* Make location more compact */
  .location-container {
    margin-bottom: 6px;
    max-width: 100%;
    overflow: hidden;
  }
  
  .event-location {
    font-size: 0.7rem;
    margin-bottom: 4px;
    max-width: calc(100% - 18px); /* Account for icon */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Fix any horizontal scrolling issues */
  .event-list-container,
  .event-list {
    overflow-x: hidden;
    width: 100%;
  }
  
  /* Ensure event card has proper layout on mobile */
  .event-card {
    padding: 10px !important;
    min-height: 120px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    position: relative !important;
    box-sizing: border-box !important;
    max-height: none !important; /* Remove max height limitation */
    height: auto !important; /* Allow content to determine height */
    overflow: visible !important; /* Allow button dropdown to be visible */
  }
  
  /* Make sure event card flex items stay within the container */
  .event-card a {
    flex: 1 0 auto !important;
    overflow: hidden !important;
    margin-bottom: 6px !important;
  }
  
  /* Ensure the calendar button container is properly positioned */
  .calendar-button-container {
    flex: 0 0 auto !important;
    margin-top: 4px !important;
    padding-top: 4px !important;
    position: relative !important;
    z-index: 5 !important; /* Ensure it's above other content */
    width: 100% !important;
    max-width: 100% !important;
    text-align: left !important;
  }

  /* Create a separator between content and button */
  .calendar-button-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: rgba(0,0,0,0.05);
    margin-bottom: 4px;
  }
}

@media (max-width: 360px) {
  .week-events-counter {
    display: none;
  }
}

/* Event Date */
.event-date {
  font-size: 0.875rem; /* Match text-sm (0.875rem) */
  color: var(--text-color) !important;
}

/* Calendar button container - prevent accidental touch events */
.calendar-button-container {
  touch-action: none; /* Prevent any default touch actions */
  pointer-events: auto; /* Ensure the container receives pointer events */
  width: 100%; /* Ensure it takes full width of parent */
  max-width: 100%; /* Prevent overflow */
  margin-top: auto; /* Push to bottom of flex container */
  padding: 6px 0 2px; /* Add padding to create a larger touch target */
  position: relative; /* Establish positioning context */
  z-index: 2; /* Ensure it's above other content */
  display: block; /* Block display mode */
  overflow: visible; /* Allow the button dropdown to be visible */
}

/* Target the actual add-to-calendar-button element to make it require a deliberate tap */
.calendar-button-container add-to-calendar-button {
  touch-action: none; /* Disable default touch behaviors */
  -webkit-tap-highlight-color: transparent; /* Remove tap highlight */
  user-select: none; /* Prevent text selection */
  width: 100%; /* Take full width */
  max-width: 100%; /* Prevent overflow */
  display: block; /* Block display */
  pointer-events: auto; /* Ensure it receives pointer events */
  font-weight: normal;
}

/* Make sure the button requires a deliberate tap rather than a swipe */
.calendar-button-container add-to-calendar-button button {
  pointer-events: auto; /* Ensure clicks are registered */
  touch-action: none; /* Disable default touch behaviors completely */
  transition-delay: 0.15s; /* Add delay to distinguish between taps and swipes */
  max-width: 100%; /* Keep within bounds */
  display: inline-block; /* Use inline-block display */
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Fix for event card to ensure proper layout for the calendar button */
.event-card {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
  width: 100% !important;
  box-sizing: border-box !important;
  /* Prevent overflow of internal elements except where needed */
  overflow: hidden !important; 
}

@media (max-width: 480px) {
  /* Enhanced mobile-specific settings for calendar button */
  .calendar-button-container {
    padding: 8px 4px 4px;
    margin-top: auto;
    margin-bottom: 0;
    /* Create a "tap zone" with subtle visual feedback */
    position: relative;
    max-width: 100%;
    overflow: visible;
  }
  
  /* Add a subtle tap effect */
  .calendar-button-container:active {
    opacity: 0.7;
    transition: opacity 0.1s ease;
  }
  
  .calendar-button-container add-to-calendar-button button {
    /* Make touch target larger without changing visual size */
    padding: 8px 10px !important;
    min-height: 36px !important;
    margin: 0 !important;
    width: auto !important;
    box-sizing: border-box !important;
    font-size: 0.9rem !important;
    /* Requires most deliberate press on mobile */
    touch-action: none !important;
    display: inline-block !important;
    pointer-events: auto !important;
    /* Prevent text wrapping and overflow */
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    font-weight: normal;
  }
  
  /* Ensure event card has proper layout on mobile */
  .event-card {
    padding: 10px !important;
    min-height: 120px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    position: relative !important;
    box-sizing: border-box !important;
    max-height: none !important; /* Remove max height limitation */
    height: auto !important; /* Allow content to determine height */
    overflow: visible !important; /* Allow button dropdown to be visible */
  }
  
  /* Make sure event card flex items stay within the container */
  .event-card a {
    flex: 1 0 auto !important;
    overflow: hidden !important;
    margin-bottom: 6px !important;
  }
  
  /* Ensure the calendar button container is properly positioned */
  .calendar-button-container {
    flex: 0 0 auto !important;
    margin-top: 4px !important;
    padding-top: 4px !important;
    position: relative !important;
    z-index: 5 !important; /* Ensure it's above other content */
    width: 100% !important;
    max-width: 100% !important;
    text-align: left !important;
  }

  /* Create a separator between content and button */
  .calendar-button-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: rgba(0,0,0,0.05);
    margin-bottom: 4px;
  }
}